<template>
<b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
    <b-row>
      <div class="col-md-6" >
        <b-card  title="Montant total gagné" >
         <h2> {{ totalComission }} €</h2>
        </b-card>
     
      </div>

      <div class="col-md-6" >
        <b-card title="Solde actuel" >
          <h2>{{ totalComission - totalPayouts }} €</h2> 
          
        </b-card>
        <b-button @click="sendPaymentRequest" v-if="totalComission>0 && activePayment">Demander le paiement</b-button>
      </div>

    </b-row>
    <br /> <br />
  <b-card title="Ventes">
    <b-row>
    <VueApexCharts class="col-md-6"  type="line" :options="options" :series="series"/>
    <VueApexCharts class="col-md-6"  type="bar" :options="options" :series="series" />
    </b-row>
  </b-card>
  <b-card title="Virements effectués" v-if="activePayment"> 
  <vue-good-table
        v-if="!loading"
          :columns="columns"
          :rows="payouts"
          :pagination-options="{
            enabled: false,
           
          }"
        >
        
          <template 
            slot="table-row"
            slot-scope="props"
            
          >
          <span
          v-if="props.column.field === 'amount'"
          class="text-nowrap"
        >
         
            <span class="text-nowrap">{{(props.row.amount/100).toFixed(2)}}€</span>
        
        </span>

        <span
          v-else-if="props.column.field === 'created'"
          class="text-nowrap"
        >
         
            <span class="text-nowrap">{{convertTimestamp(props.row.created)}}</span>
        
        </span>


            
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        
        </vue-good-table>
      </b-card>
</b-overlay>
</template>

<script>
import {BModal, BRow, BButton, VBModal,BOverlay, BCard, BCardText, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, } from 'bootstrap-vue'
import {APIRequest, Woocommerce,Auth} from '@simb2s/senseye-sdk'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VueApexCharts from 'vue-apexcharts'
export default {
  components: {
    BCard,
    BCardText,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BRow,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    VueApexCharts,
    BDropdownItem,
    BModal,
    BOverlay,
    BButton
  },
  data() {
    return {
      orders:[],
      pageLength: 10,
      dir: false,
      payouts:null,
      totalPayouts:0,
      activePayment:false,
      loading:true,
      glasses:[],
      totalRows:0,
      currentPage:1,
      columns: [
        {
          label: 'Montant',
          field: 'amount',
        },
        {
          label: 'Effectué le',
          field: 'created',
        }
        
      ],
      selectedItem:null,
      
      rows: [],
      searchTerm: '',
      options: {
        chart: {
          id: 'vuechart-example'
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
        }
      },
      series: [{
        name: 'series-1',
        data: [30, 40, 45, 50, 49, 60, 70, 91]
      }],
      totalComission:0,

    }
  },
  methods:{
    convertTimestamp(ts){
      let date=new Date(ts * 1000)

      
      return date.toLocaleDateString('fr-FR')
    },
    async sendPaymentRequest()
    {
      await APIRequest.send_payment_request(localStorage.id_user_senseye_auth,this.totalComission-this.totalPayouts)
      this.$toast({
          component: ToastificationContent,
          props: {
              title: 'La demande de paiement a été envoyée !',
              icon: 'CheckIcon',
              variant: 'success',
          },
      })
    }
  },
  watch:{
    
  
   
  },
  async mounted()
  {
    let scans=await APIRequest.get_comissions(localStorage.id_user_senseye_auth)
    this.totalComission=scans.total

    let user_data=await Auth.get_user_with_id(localStorage.id_user_senseye_auth)
   

    if(user_data.stripe_id)
    {
      this.activePayment=true
      let payouts=await APIRequest.get_payouts_stripe(user_data.stripe_id)

      this.totalPayouts=0
      this.payouts=payouts.data
      for(let i=0;i<payouts.data.length;i++)
      {
      this.totalPayouts=this.totalPayouts+payouts.data[i].amount/100
      }
    }
      

   

    // on get tous les produits assignés

    // let scans=await APIRequest.list_with_request('glasses', '?creator_owner='+localStorage.id_user_senseye_auth)
    // //let scans=await APIRequest.list('glasses')
    // this.glasses=scans[0]

    // // pour chaque produit on get les orders
    // this.orders=[]
    // for(let i=0;i<this.glasses.length;i++)
    // {
      
    //   let request='?model='+this.glasses[i].id+'&limit=1000'
    //   let orders=await APIRequest.list_with_request('order-items', request)

     
    //   orders=orders[0]

    //   let orders_final=[]

    //    // on vérifie si elle existe deja
    //   for(let j=0;j<orders.length;j++)
    //   {
    //     let exists=false

    //     for(let p=0;p<this.orders.length;p++)
    //     {
    //       if(orders[j].id==this.orders[p].id)
    //       {
    //         exists=true
    //       }
    //     }
    //     if(exists==false)
    //     {
    //       orders_final.push(orders[j])
    //       this.orders.push(orders[j])
    //     }
    //   }

    //   //this.orders.push(orders_final)


    // }

    // console.log('ok part 1')

    // // pour chaque order on recup la/les lignes concernées par un produit
    


    // for(let i=0;i<this.orders.length;i++)
    // {

    //   console.log(this.orders[i])
    //     // on calcule la comission pour la ligne et on l'ajoute au total
    //       for(let j=0;j<this.glasses.length;j++)
    //       {
    //         if(this.glasses[j].id==this.orders[i].glass.id)
    //         {
    //           if(this.glasses[j].comission)
    //           {
              
    //             this.totalComission=this.totalComission+((this.orders[i].amount/100)*this.glasses[j].comission)
    //           }
    //         }
       
          
    //   }
    // }
      
    // for(let i=0;i<this.orders.length;i++)
    // {
    //   console.log('test order')
    //   console.log(this.orders)
    //   let order_detail=await APIRequest.detail('orders', this.orders[i].id)
    //   if(order_detail.order_items)

    //   {
    //     for(let p=0;p<order_detail.order_items.length;p++)
    //     {
    //       for(let j=0;j<this.glasses.length;j++)
    //       {
    //         if(this.glasses[j].id==order_detail.order_items[p].glass.id)
    //         {

    //           // on calcule la comission pour la ligne et on l'ajoute au total

    //           if(order_detail.order_items[p].glass.comission)
    //           {
    //             this.totalComission=this.totalComission+((order_detail.order_items[p].amount/100)*order_detail.order_items[p].glass.comission)
    //           }
              
    //         }
    //       }
    //     }
    //   }
      

    // }



    // on recup tous les payouts effectués

    // on calcule le solde total du montant du


    this.loading=false
  }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
